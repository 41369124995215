@tailwind base

@import ./_variables
@import bootstrap/scss/variables
@import bootstrap/scss/functions
@import bootstrap/scss/maps
@import bootstrap/scss/mixins
@import bootstrap/scss/utilities
@import bootstrap/scss/helpers
@import bootstrap/scss/utilities/api
@import bootstrap/scss/reboot
@import bootstrap/scss/containers
@import bootstrap/scss/grid
@import react-modern-drawer/dist/index.css
@import antd/dist/antd.css
@import leaflet/dist/leaflet.css
@import react-smartbanner/dist/main.css
@import react-contexify/ReactContexify.css
// @import slick-carousel/slick/slick.css
// @import slick-carousel/slick/slick-theme.css
@import https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css
@import https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css
@import @vayapin/react-ui-web/styles/legacy/base.sass
@import ./antd-overrides
@import ./modal

body,
html
  background-color: #f5f7f9
  background-repeat: repeat
  font-family: $font-primary

:root
  --bs-white-rgb: 255, 255, 255

#app,
body,
html
  box-sizing: border-box
  height: 100%
  margin: 0
  padding: 0
  position: relative
  width: 100%

  > main
    margin: 0
    padding: 0

.EZDrawer
  position: relative
  z-index: $z-index-drawer

hr
  background-color: rgba(#000, .5)
  border-bottom: 1px solid rgba(#fff, .5)

h1
  font-size: 26px
  font-weight: normal
  text-align: center


.button-reset
  background-color: transparent
  border: 0
  inset: 0
  margin: 0
  outline: 0
  padding: 0

.cc-window.cc-floating
  max-width: none !important

.cc-floating .cc-message
    margin-bottom: 0 !important

.cc-floating.cc-theme-classic
  padding: 0.5em 1em !important
  border-radius: unset !important

.cc-btn.cc-allow
  min-width: unset !important

.cc-window.cc-floating
  .cc-compliance
    flex: unset !important

.cc-btn.cc-deny
  background-color: #AAA !important

.cc-revoke
  display: none !important

// Overwrites for bootstrap
.bg-white
  background-color: #fff !important
