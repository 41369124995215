@import styles/_variables

//
// Button
.ant-btn-primary
  background-color: $color-primary
  border-color: darken($color-primary, 5%)

  &:hover
    background-color: lighten($color-primary, 5%)

.ant-btn-link
  color: $color-primary

  &:hover
    color: lighten($color-primary, 5%)

// Input
.ant-input-affix-wrapper
  background-color: rgb(250, 251, 252)
  border: 2px solid rgb(223, 225, 230)
  border-radius: 3px

  > input.ant-input
    background-color: transparent

.ant-form-item-has-error
  .ant-input,
  .ant-input-affix-wrapper,
  .ant-input:hover,
  .ant-input-affix-wrapper:hover
    border-color: #ff9294

// Select
.ant-select:not(.ant-select-customize-input)
  .ant-select-selector
    background-color: rgb(250, 251, 252)
    border: 2px solid rgb(223, 225, 230)
    border-radius: 3px

// Modal
.ant-modal-content
  border-radius: 10px

.ant-modal-mask
  background-color: rgba(0, 0, 0, 0.8)
