@import bootstrap/scss/functions

// colors
$color-primary: #00cc65
$color-secondary: #ff0066
$color-info: #91d5ff
$color-danger: #eb0a0e

// font
$font-primary: 'Poppins', -apple-system, BlinkMacSystemFont, Oxygen, Ubuntu, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif

// z-indexs
$z-index-staging-note: 999
$z-index-header: 1000
$z-index-drawer: 1001

// layout
$layout-width: 500px

// header
$header-height: 3.5rem

// modal
$modal-header-height: 3rem
$modal-width: $layout-width + 2 * 16px

// input
$input-color-error: rgb(255, 77, 79)
$input-color-success: rgb(82, 196, 26)

// select
$input-select-bg-disabled: #f2f2f2

// button

$button-default-color: #222
$button-default-color-hover: #000
$button-default-color-disabled: #aaa
$button-default-bg: #eee
$button-default-bg-hover: darken($button-default-bg, 5%)
$button-default-bg-disabled: lighten($button-default-bg, 2%)

$button-primary-color: #fff
$button-primary-color-hover: #fff
$button-primary-color-disabled: rgba(#fff, .75)
$button-primary-bg: $color-primary
$button-primary-bg-hover: darken($button-primary-bg, 5%)
$button-primary-bg-disabled: lighten($button-primary-bg, 15%)

$button-secondary-color: #fff
$button-secondary-color-hover: #fff
$button-secondary-color-disabled: rgba(#fff, .75)
$button-secondary-bg: $color-secondary
$button-secondary-bg-hover: darken($button-secondary-bg, 5%)
$button-secondary-bg-disabled: lighten($button-secondary-bg, 5%)

$button-info-color: #333
$button-info-color-hover: #333
$button-info-color-disabled: rgba(#333, .5)
$button-info-bg: #91d5ff
$button-info-bg-hover: darken($button-info-bg, 5%)
$button-info-bg-disabled: lighten($button-info-bg, 5%)

$button-danger-color: #fff
$button-danger-color-hover: #fff
$button-danger-color-disabled: rgba(#fff, .75)
$button-danger-bg: #eb0a0e
$button-danger-bg-hover: darken($button-danger-bg, 5%)
$button-danger-bg-disabled: lighten($button-danger-bg, 10%)

$button-outline-color: rgba(#333, .85)
$button-outline-color-hover: lighten($button-outline-color, 5%)
$button-outline-color-disabled: #aaa
$button-outline-border: lighten($button-outline-color, 20%)
$button-outline-border-hover: darken($button-outline-border, 5%)
$button-outline-border-disabled: lighten($button-outline-border, 2%)

$button-outline-primary-color: darken($color-primary, 5%)
$button-outline-primary-color-hover: lighten($button-outline-primary-color, 5%)
$button-outline-primary-color-disabled: lighten($color-primary, 10%)
$button-outline-primary-border: $color-primary
$button-outline-primary-border-hover: darken($button-outline-primary-border, 5%)
$button-outline-primary-border-disabled: lighten($button-outline-primary-border, 2%)

$button-outline-secondary-color: darken($color-secondary, 5%)
$button-outline-secondary-color-hover: lighten($button-outline-secondary-color, 5%)
$button-outline-secondary-color-disabled: lighten($color-secondary, 10%)
$button-outline-secondary-border: $color-secondary
$button-outline-secondary-border-hover: darken($button-outline-secondary-border, 5%)
$button-outline-secondary-border-disabled: lighten($button-outline-secondary-border, 2%)

$button-outline-info-color: darken($color-info, 10%)
$button-outline-info-color-hover: lighten($button-outline-info-color, 5%)
$button-outline-info-color-disabled: lighten($color-info, 10%)
$button-outline-info-border: $color-info
$button-outline-info-border-hover: darken($button-outline-info-border, 5%)
$button-outline-info-border-disabled: lighten($button-outline-info-border, 2%)

// deprecated /legacy
$mobile-breakpoint: 400px

// bootstrap
@import bootstrap/scss/variables
@import bootstrap/scss/mixins
